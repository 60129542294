.title {
    color: $title;
}

.fs-325 {
    font-size: $fs-325;
}

.color-aqua {
    color: $color-aqua;
}

.modal-dialog-1 {
    max-width: 92%;
}

.greyish-brown {
    color: $greyish-brown;
}

.modal-content {
    background-color: $gray-modal;
}
/* inicia yellow table hover */

.yellowHover{
    width:90%;
    border-collapse:collapse;
}
/*  hover  color => table row */
.yellowHover tr:hover {
      background-color: rgba(255, 195, 0, 0.22);
}
/* termina yellow table hover */

.titulos-modal{
  font-size: $fs-16;
  font-weight: bold;
  line-height: 1.7rem;
  color: $greyish-brown;
  text-align: left;
  margin-top: 0;
  margin-bottom: .8rem;
}

.text-card-modal{
  font-size: $fs-12;
  color: $gray-17;
  text-align: left;
  font-weight: bold;
}

.card-custom{
  background-color: $white;
  border: solid 0.07rem $gray-border-search;
  border-radius: 0.07rem;
}

.card-body-custom{
  padding: 1rem;
}

.line-modal{
  border-top: solid .07rem $gray-border-search;
  margin-bottom: 2rem;
}

.scroll{
    max-height: 24rem;
    overflow-y: auto;
}

.row-margin{
  margin-left: -1rem;
  margin-right: -1rem;
}

.scroll .card-body{
  padding: 1rem;
}

.modal-footer-custom{
  border-top: solid .14rem $gray-border-search;
}

.btn-modal{
  padding: .5rem 2rem;
  font-size: $fs-22;
  color: white;
  text-align: center;
  font-weight: 600;
  background-color: $color-primary;
  border-radius: .5rem;
  min-height: .5rem;
  margin: 2rem;
}

.btn-modal:hover{
  background-color: $color-aqua;
  color: white;
}

@include media-breakpoint-up(lg) {

  .modal-cards [class^=col] {
    flex-basis: calc(100% / 5);
    max-width: calc(100% / 5);
  }
}
