.row-cards {
  margin-left: -6px;
  margin-right: -6px;

  [class^=col] {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.row-filters {
  margin-left: -2px;
  margin-right: -2px;

  [class^=col] {
    padding-left: 2px;
    padding-right: 2px;
  }
}
