.btn-gray-12 {
  @include button-variant($gray-12, $gray-12);
}

.btn-filter-coral {
  @include button-variant($color-coral, $color-coral);
}

.btn-filter-blue {
  @include button-variant($color-blue, $color-blue);
}
.cursor-auto{
  cursor:  auto;
}
.btn-access-table{
	font-size: 17px;
    margin-top: 6px;
    height: 35px;
    padding: 5px 15px;
    background-color: #f0f0f0;
    color: #71415a!important;
}

.btn-dashboard{
	font-size: 17px;
    margin-top: 6px;
    height: 35px;
    padding: 5px 15px;
    background-color: #4a4e4e;
    color: #fff!important;
}

.btn-filter {
  border-radius: 5px;
  height: 100%;
  line-height: normal;
  min-height: 50px;

  &__display {
    flex-basis: 85px;
    font-size: 3rem;
    @include media-breakpoint-up(xl) {
      flex-basis: 105px;
    }
  }

  &:not(:disabled):not(.disabled).active {
    background-color: #3b3b3b;
    border-color: #3b3b3b;
    color: $white;
  }
}
