@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Bold.otf");
  font-weight: bold;
}
@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-SemiBold.otf");
  font-weight: 600;
}

@each $color, $value in $text-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

.font-weight-semibold {
  font-weight: 600;
}
