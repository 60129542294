.custom-switch-double {

  .custom-control-label {

    &::before,
    &::after {
      bottom: 0;
      margin: auto;
      top: 0;
    }

    &::before {
      background-color: $gray-switch;
      border-color: $gray-switch;
      height: 1.5rem;
    }

    &::after {
      background-color: $color-green;
      border-radius: 50%;
      height: 1.9rem;
      left: -4.25rem;
      width: 1.9rem;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {

    &::before {
      background-color: $gray-switch;
      border-color: $gray-switch;
    }

    &::after {
      background-color: $color-green;
      transform: translateX(2rem);
    }
  }
}

/* //checkbox color */
/* The check */
.check {
  cursor: pointer;
  display: block;
  margin-bottom: 0.8rem;
  padding-left: 3.5rem;
  position: relative;
  user-select: none;

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $color-blue;

      &::after {
        display: block;
      }
    }
  }

  &:hover input ~ .checkmark {
    background-color: $color-blue;
  }

  .checkmark::after {
    border-width: 0 3px 3px 0;
    border: solid $white;
    height: 10px;
    left: 9px;
    top: 5px;
    transform: rotate(45deg);
    width: 5px;
  }
}

/* Create a custom checkbox */
.checkmark {
  background-color: $gray-modal;
  border-radius: 2px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;

  /* Create the checkmark/indicator (hidden when not checked) */
  &::after {
    content: "";
    display: none;
    position: absolute;
  }
}

.form-login-table {
  font-size: 15px;
  padding: 40px 0;
  input{
    height: 35px;
    font-size: 15px;
  }
  button{
    height: 35px;
    font-size: 15px;
  }
}

form.messages-search {
  position: relative;

  .search-recipients {
    border-color: #979797;
    color: #414141;
    font-size: 11px;
    font-weight: 700;
    padding: 0 24px 0 10px;
  }

  .button-search {
    bottom: 0;
    display: flex;
    height: 100%;
    margin: auto;
    position: absolute;
    right: 6px;
    top: 0;

    img {
      width: 15px;
    }
  }
}
