body {
  min-height: 100vh;
}

.dashboard-html {
  font-size: 62.5%;
}

/**Utilidades**/
.no-margin {
    margin: 0;
}

.w-10 {
    width: 10%;
}

.w-90 {
    width: 90%;
}
/**Header**/
.barra {
    display: block;
    background-color: $gray-nav;
    min-height: 3.2rem;
    width: 100%;
    border-top: solid 0.3rem $gray-border-nav;
    margin: 1.5rem 0;
}

.navegacion {
    display: flex;
    justify-content: space-between;
}

.navegacion a {
    padding: 0.7rem 1.9rem;
    font-weight: normal;
    font-size: $fs-15;
    color: $gray-09;
    text-decoration: none;
    line-height: normal;
}

.navegacion a.active,
.navegacion a:hover {
    font-weight: bold;
    color: $color-primary;
}

.titulo {
    font-weight: bold;
    line-height: normal;
    text-align: left;
    font-size: $fs-17;
    color: $gray-09;
    margin: 0;
}

.text-switch {
    font-size: $fs-15;
    font-weight: bold;
    color: $gray-06;
    margin: auto;
    padding: 0.5rem;
    text-align: center;
}

@media (min-width: 992px) {
    .border-separate {
        border-left: solid 0.1rem $gray-border-search;
    }

    .border-separate2 {
        border-right: solid 0.1rem $gray-border-search;
    }
}

.rectangle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28.7rem;
    height: 8.8rem;
    margin: 0 auto;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.7rem 0 rgba(black, .22);
}

.rectangle.active {
    background-color: white;
}

.rectangle:focus {
    outline: none;
}

.envia-mensajes p,
.envia-mensajes p span {
    font-weight: bold;
    color: $gray-04;
    text-align: left;
    line-height: normal;
}

.envia-mensajes p {
    padding: 2.3rem;
    font-size: $fs-13;
}

.envia-mensajes p span {
    font-size: $fs-11;
}

.barra2 {
    display: block;
    background-color: $gray-01;
    min-height: 2.8rem;
    padding: 0.5rem;
}

.barra3 {
    display: block;
    background-color: $gray-01;
    padding: 0.4rem;
}

.aviso-emergencia {
    font-size: $fs-14;
    color: $gray-10;
    text-align: center;
}

.titulo-busqueda {
    font-size: $fs-14;
    font-weight: bold;
    color: $gray-10;
    text-align: left;
}

.nav-link-customize,
.nav-pills-customize {
    border-radius: 0.6rem;
}

.nav-link-customize {
    padding: 1rem;
}

.nav a:not([data-toggle=collapse]) {
  background-color: $gray-02;
  color: $gray-nav2;
  font-size: $fs-13;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;

  + a {
    margin-top: 0.8rem;
  }

  &.active,
  &:hover {
    background-color: $gray-12;
    color: $white;
  }
}

.tabla {
    border-bottom: solid 0.1rem $gray-border-search;
}

.tabla td {
    font-size: $fs-12;
    color: $gray-07;
    line-height: 1.4rem;
    text-align: left;
    font-weight: normal;
    padding: 1rem;
}

.tabla th {
    font-size: $fs-12;
    color: $gray-07;
    line-height: 1.6rem;
    text-align: left;
    font-weight: bold;
    padding: 1rem;
}

.btn-01 {
    display: inline-block;
    background-color: $color-primary;
    border-radius: 0.5rem;
    margin: auto 0 1rem;
    height: 2.2rem;
    width: auto;
}

.btn-01 a {
    display: flex;
    align-items: center;
    color: white;
    font-size: $fs-11;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    padding: 0.3rem 1.5rem;
}

.btn-01:hover {
    background-color: $color-aqua;
}

.contacto-inmueble,
.direccion-inmueble,
.tipo-inmueble {
    text-align: left;
    font-size: normal;
    color: black;
}

.subtitulos {
    text-align: left;
    color: $color-primary;
    font-weight: bold;
    font-size: $fs-17;
}

.fotografias {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.fotografias img {
    margin: 1rem 1rem 1rem 0;
}

.formato-tabla {
    padding-left: 2rem;
}

table .contenido-tabla {
    color: $gray-14;
    text-align: left;
    font-size: $fs-13;
    font-weight: bold;
}

table .contenido-tabla td {
    padding-left: 1.5rem;
}

table .contenido-tabla td:last-of-type {
    padding-left: 0.5rem;
}

table .contenido-tabla .fecha td {
    padding-left: 1.5rem!important;
}

table .fecha {
    color: $gray-14;
    text-align: left;
    font-size: $fs-10;
    font-weight: normal;
    border-bottom: solid 0.1rem $gray-15;
}

table .fecha:last-of-type {
    border-bottom: 0;
}

.footer {
    background-color: $gray-13;
    min-height: 6rem;
    padding: 1.5rem;
    margin-top: 1.5rem;
    color: white;
}
/* tablero emergencia 02 */
.costohumano-bg {
    /* display: block; */
    background-color: $gray-nav;
    width: 100%;
}

.flexGrow {
    flex-grow: 2;
    flex-basis: 100%;
    justify-self: stretch;
    height: 100%;
}

.alignGray {
    display: inline-block;
    vertical-align: middle;
    float: none;
}
/* termina tablero emergencia 02 */

.mr-2{
    margin-right: 12px;
}
