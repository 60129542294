/* Source Sans Pro */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

/* Open Sans */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

/* Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700;800;900&display=swap');

.fs-10 {
  font-size: $fs-10;
}

.fs-11 {
  font-size: $fs-11;
}

.fs-12 {
  font-size: $fs-12;
}

.fs-13 {
  font-size: $fs-13;
}

.fs-14 {
  font-size: $fs-14;
}

.fs-15 {
  font-size: $fs-15;
}

.fs-16 {
  font-size: $fs-16;
}

.fs-17 {
  font-size: $fs-17;
}

.fs-19 {
  font-size: $fs-19;
}

.fs-20 {
  font-size: $fs-20;
}

.fs-22 {
  font-size: $fs-22;
}

.fs-23 {
  font-size: $fs-23;
}

.fs-24 {
  font-size: $fs-24;
}

.fs-25 {
  font-size: $fs-25;
}

.fs-28 {
  font-size: $fs-28;
}

.fs-32 {
  font-size: $fs-32;
}

.fs-325 {
  font-size: $fs-325;
}
.fs-51 {
  font-size: $fs-51;
}
