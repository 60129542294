@font-face {
    font-family: 'Gotham';
    font-weight: 400;
    src: url('../fonts/Gotham-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-weight: 100;
    src: url('../fonts/Gotham-Light.otf') format('opentype');
}

///// VARIABLES

html {
  scroll-behavior: smooth;
}

body button, body>label {
    font-weight: 400;
    font-size: ms(0);
    border-radius: 5px;
}

body button{
    background-color: #AAAAAA;
    color: $white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:disabled{
        background-color: #AAAAAA;
    }
    &.active {
        background-color: #71415a;
    }
    &.verde {
        background-color: #71415a;
    }
}


h1,h2,h3,h4,h5 {
    font-weight: 400;
    width: 100%;
    &:disabled{
        color: #AAAAAA;
    }
}

h1 {
    color: $white;
}


input, select {
    border: #E5E5E5 solid 1px;
    border-radius: 5px;
    font-size: .8em;
    font-weight: 100;
    height: 2em;
    &:disabled{
        background-color: #AAAAAA;
    }
    &.codigo-postal {
        height: 44px;
        text-align: center;
        width: 250px;
    }
}

a {
    color: black;
    text-decoration: underline;
}

p {
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 20px;
    & > b{
        font-weight: 400;
    }
}

/** Versión pasada 
#header-logo{
	//width: 180px !important;
}

#hero {
    margin-top: 0px;
    position: relative;
    //background-image: url('img/sismo.jpg');
    background-color: grey;
    background-size: cover;
    background-position: center;
    height: 35vh;
    //width: 100vw;
    &> #intro{
        position: absolute;
        display: flex;
        flex-flow: column;
        &> a{
            margin-top: 1em;
            font-weight: 400;
        }
        &> #logo-hero{
            margin: 0px;
            width: 342px;
        }
    }
}

#personas-registradas {
    h2 {
        font-size: 26px;
    }
    .voluntarios h2 {
        font-size: 15px;
        color: #828282;
        margin-top: 28px;
        margin-bottom: 10px;
    }
    .voluntarios p {
        font-size: 10.8px;
        color: #828282;
    }
}

#perfil, #send, #horario {
    border-bottom: $grey 1px solid;
    padding-bottom: 2em;
}

.numero-de-turnos-faltantes {
    text-align: center;
    h2 {
        font-weight: bold;
        font-size: 18px;
    }
    span {
        font-weight: bold;
    }
}

.btn_especialidad{
    background-color: rgba(0, 0, 0, 0.6);
    border: 3px solid transparent;
    color: white;
}

.btn_especialidad.active{
    background-color: rgba(27, 182, 0, 0.6);
}

.btn_especialidad label span{
    color:#fff !important;
}

#aviso-de-privacidad {
    background-color: #EBEBEB;
}

#send {
    display: grid;
    align-content: center;
    justify-content: center;
    padding: 2em 0;
}
*/

#intro-blurb {

    position: absolute;
    top: 25px;
    right: 25px;
    width: 180px;
    padding: 1em;
    background-color: rgba($color: #000000, $alpha: .80);
    &>p{
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        margin: 0;
    }
    @include media('<920px'){
        position: relative;
        top: 20vh;
    }
}

a.verde {
    color:#71415a;
    font-weight: 400;
}

.punto {
    height: 19px;
    width: 19px;
    opacity: 0.57;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    &.rojo {
        background-color: #ff0000;
    }
    &.amarillo {
        background-color: #f5a623;
    }
    &.verde {
        background-color: #1bb600;
    }
}

body button{
    background-color: #AAAAAA;
    color: $white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:disabled{
        background-color: #AAAAAA;
    }
    &.active {
        background-color: #71415a;
    }
    &.verde {
        background-color: #71415a;
    }
}

#descripcion{
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    bottom: 0px;
    max-width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    color: $white;
    padding: 15px;
    & > #flechas {
        margin: 1em;
    }
    p {
        margin: 0;
        font-size: 11px;
        line-height: 13px;
    }
}

.schedule {
    display: flex;
    flex-wrap: wrap;

    &__item {
        align-items: center;
        display: flex;
        flex-grow: 1;
        margin-bottom: 0.75rem;
        text-align: center;
        width: calc(100% / 8);

        label {
            background-color: hsla(353, 98%, 41%, 0.6);
            border: 3px solid transparent;
            color: white;
            font-size: 0.75em;
            -moz-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            padding: 8px 0;
            width:100%;

            &:hover {
                background-color: hsla(353, 98%, 41%, 0.8);
            }

            &:active {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            }

            &::before {
                // content: 'Vacío';
            }
        }

        &.full label {
            background-color: hsla(111, 100%, 36%, 0.6);

            &:hover {
                background-color: hsla(111, 100%, 36%, 0.8);
            }

            &::before {
                // content: 'Completo';
            }
        }

        &.incomplete label {
            background-color: hsla(37, 91%, 55%, 0.6);

            &:hover {
                background-color: hsla(37, 91%, 55%, 0.8);
            }

            &::before {
                // content: 'Incompleto';
            }
        }

        &.active label {
            border-color: #484848;
        }
    }

    &__title {
        font-size: 1em;
        overflow: hidden;
    }
}

.schedulespecialty {
    display: flex;
    flex-wrap: wrap;

    &__item {
        align-items: center;
        display: flex;
        flex-grow: 1;
        margin-bottom: 0.75rem;
        text-align: center;
        width: calc(100% / 8);

        label {
            background-color: hsla(353, 98%, 41%, 0.6);
            border: 3px solid transparent;
            color: white;
            font-size: 0.75em;
            -moz-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            padding: 8px 0;
            width:100%;

            &:hover {
                background-color: hsla(353, 98%, 41%, 0.8);
            }

            &:active {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            }

            &::before {
                // content: 'Vacío';
            }
        }

        &.full label {
            background-color: hsla(111, 100%, 36%, 0.6);

            &:hover {
                background-color: hsla(111, 100%, 36%, 0.8);
            }

            &::before {
                // content: 'Completo';
            }
        }

        &.incomplete label {
            background-color: hsla(37, 91%, 55%, 0.6);

            &:hover {
                background-color: hsla(37, 91%, 55%, 0.8);
            }

            &::before {
                // content: 'Incompleto';
            }
        }

        &.active label {
            border-color: #484848;
        }
    }
}

.botones-registro {
    a.btn-ayuda {
        width: 390px;
        height: 160px;
        display: block;
        border: 5.6px solid #adadad;
        border-radius: 19.2px;
        margin: 0 auto;
        position: relative;
        background-size: 100px !important;
        &.fija {
            background: url('../img/ayuda-voluntaria-fija-gris.svg') 15px 24px no-repeat;

            &:hover, &.active {
                border-color: #1bb600;
                background-image: url('../img/ayuda-voluntaria-fija.svg');
            }
        }
        &.movil {
            background: url('../img/ayuda-voluntaria-movil-gris.svg') 15px 25px no-repeat;
            &:hover, &.active {
                border-color: #1bb600;
                background-image: url('../img/ayuda-voluntaria-movil.svg');
            }
        }
        &.especializado {
            background: url('../img/ayuda-voluntaria-especializado-gris.svg') 15px 12px no-repeat;
            &:hover, &.active {
                border-color: #1bb600;
                background-image: url('../img/ayuda-voluntaria-especializado.svg');
            }
        }
        span.titulo {
            font-size: 25.4px;
            color: #222222;
            font-weight: bold;
            position: absolute;
            top: 35px;
            left: 153px;
            text-align: left;
        }
        span.descripcion {
            font-size: 11.2px;
            color: #222222;
            font-weight: 300;
            position: absolute;
            top: 76px;
            left: 153px;
            text-align: left;
            line-height: normal;
        }
    }
}



.cursor-pointer{
    cursor: pointer;
}

.label-cp-response {
    span {
        font-weight: bold;
    }
}

#searchbox {
    position: relative;
    z-index: 400;
    text-align: center;
    width: 100%;
    height: 65px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 10px 5px 10px;
    label.colonia {
        color: white;
        font-size: 24px;
        font-weight: normal;
        padding-right: 25px;
    }
    input.colonia-input {
        width: 586px;
        height: 40px;
        padding: 5px;
        vertical-align: top;
        margin-right: 15px;
    }
    button {
        vertical-align: top;
        padding:8px 15px;
    }
}

#searchbox_container{
    padding:0 !important;
}

#resultados-colonia {
    background-color: white;
    position: absolute;
    width: 580px;
    top: 51px;
    left: 50%;
    margin-left: -271px;
    li {
        text-align: left;
        border-bottom: 1px solid #cecece;
        padding: 5px 10px;
    }
    li:hover {
        background-color: #cecece;
        cursor: pointer;
    }
}

#map {
    height: 85vh;
    width: 100%;
    position: relative;
    z-index: 0;
}

#MapLocation {
    height: 62vh;
    width: 100%;
    position: relative;
    z-index: 0;
}

.label-cp-response {
    span {
        font-weight: bold;
    }
}

.horario{
    display: flex;
    flex-flow: row ;
}

.dia {
    display: flex;
    margin:.5em;
    color: $white;
    background-color:#AAAAAA;
    border-radius: 5px;
    border:none;
    overflow:hidden;
    align-content: center;
    justify-content: center;
    width: 25%;
    padding: .5em;
}

.dia:hover {
    background-color: #7AD21E;
}

.dia.active {
    background:#68B418;
}

.dia label {
    float:left;
    width:100%;
    text-align: center;
    padding: 5px;
    align-self: center;
    vertical-align: center;
    cursor: pointer;
}

.dia label span {
    text-align:center;
    vertical-align: center;
    padding:3px 3px;
    display:block;
}

.dia label input {
    display:none;
    vertical-align: center;
}

.dia input:checked + span {
    background-color:#68B418;
    color:#fff;
}

.schedule {
    display: flex;
    flex-wrap: wrap;

    &__item {
        align-items: center;
        display: flex;
        flex-grow: 1;
        margin-bottom: 0.75rem;
        text-align: center;
        width: calc(100% / 8);

        label {
            background-color: hsla(353, 98%, 41%, 0.6);
            border: 3px solid transparent;
            color: white;
            font-size: 0.75em;
            -moz-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            padding: 8px 0;
            width:100%;

            &:hover {
                background-color: hsla(353, 98%, 41%, 0.8);
            }

            &:active {
                box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            }

            &::before {
                // content: 'Vacío';
            }
        }

        &.full label {
            background-color: hsla(111, 100%, 36%, 0.6);

            &:hover {
                background-color: hsla(111, 100%, 36%, 0.8);
            }

            &::before {
                // content: 'Completo';
            }
        }

        &.incomplete label {
            background-color: hsla(37, 91%, 55%, 0.6);

            &:hover {
                background-color: hsla(37, 91%, 55%, 0.8);
            }

            &::before {
                // content: 'Incompleto';
            }
        }

        &.active label {
            border-color: #484848;
        }
    }

    &__title {
        font-size: 1em;
        overflow: hidden;
    }
}

.input-field-array{
    display:flex;
    flex-flow: column;
    border-radius: 5px;
    margin-top: 1em;
    & > input{
        //padding: 5px;
        margin-top: 5px;
        //height: 1.5em;
    }
}

.hidden {
    display: none;
}

@media only screen and (max-width: 700px) {
    .alertaActivacion{
        position: fixed !important;
        top: 5px;
        right:2%;
        width: 97%;
        z-index: 9999;
    }

}

@media only screen and (min-width: 700px) {
    .alertaActivacion{
        position: fixed !important;
        top: 5px;
        right:2%;
        width: 50%;
        z-index: 9999;
    }

}

@media only screen and (min-width: 1000px) {
    .alertaActivacion{
        position: fixed !important;
        top: 5px;
        right:2%;
        width: 30%;
        z-index: 9999;
    }
}

// #login_btn{
//     height: 50px;
//     background-color: #f0f0f0;
//     color: #71415a !important;
// }

.input_login{
    border-color: #71415a;
    padding-left: 10px;
    height: 32px;
    border-radius: 6px;
}

.tippy-tooltip.custom-theme {
  border-radius: 3px;
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(#000, 0.5);
  color: #4a4e4e;
  padding: 20px;

  &[data-animatefill] {
    background-color: transparent;
  }

  .tippy-backdrop {
    background-color: #fff;
  }
}

.width_200{
    width: 200px !important;
}
.no-text-decoration{
    text-decoration: none !important;
}

#cursos_content_left,.img_desc_user{
    border-right: solid;
    border-right-width:2px;
    border-right-color:#dfdfe8;
}

.nombre_user_curso{
    font-size:22px;
    color:#0f4c42;
    font-weight: bold;
}

.text_verde{
    color:#71415a;
    font-weight: bold;
}

.font-size-18{
    font-size: 18px;
}

.color-verde-oscuro{
    color: #0f4c42;
}

.bold{
    font-weight: bold;
}

.h-100px{
    height: 100px;
}

.num_curso_completo{
    font-size: 30px;
    color: #0f4c42;
    font-weight: bold;
    background-color:#dfdfe8;
}

.num_curso_faltante{
    font-size: 30px;
    color: white;
    font-weight: bold;
    background-color:#71415a;
}

.titulo_curso{
    color: #71415a;
    font-size: 18px;
    font-weight: bold;
}

.curso_bg_gris{
    background-color:#f8f8f8;
}

#informacion_colonia{
    border: solid #71415a 2px;
    padding-bottom: 1em;
}

.font-size-12{
    font-size: 12px;
}

.font-size-28{
    font-size: 28px;
}

.color-gris{
    color:#9c9c9c;
}

.color-gris-oscuro{
    color:#696969;
}

.circulo-gris-line {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  //border: 2px solid;
  position: relative;
  background-color: #00664d;
}

.circulo-gris-line:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 40%;
  border: 1px solid #00664d;
  border-width: 0 4px 0 0;
  //width: 1px;
  height: 200px;
}

.circulo-gris-mobile-line {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  //border: 2px solid;
  position: relative;
  background-color: #00664d;
}

.circulo-gris-mobile-line:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 40%;
  border: 1px solid #00664d;
  border-width: 0 4px 0 0;
  //width: 1px;
  height: 350px;
}

.circulo-gris {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  //border: 2px solid;
  position: relative;
  background-color: #00664d;
}

.circulo-verde {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  //border: 2px solid;
  position: relative;
  background-color: #71415a;
}

.numero-circulo-verde{
    font-size: 20px;
    color: white;
    left: 40%;
    top: 20%;
    position: relative;
    font-weight: bold;
}


.separador{
    border: 1px solid whitesmoke;
}

.font-size-24{
    font-size:24px;
}

.font-size-14{
    font-size:14px;
}

.font-size-16{
    font-size:16px;
}

@media only screen and (max-width: 400px) {
    .mt-texto-lineas-1{
        margin-top: 85px;
    }

}


@media only screen and (min-width: 400px) {
    .mt-texto-lineas-1{
        margin-top: 165px;
    }

}

@media only screen and (max-width: 400px) {
    .mt-texto-lineas-2{
        margin-top: 25px;
    }

}

@media only screen and (min-width: 400px) {
    .mt-texto-lineas-2{
        margin-top: 40px;
    }
}

@media only screen and (min-width: 500px) {
    .mt-texto-lineas-2{
        margin-top: 100px;
    }
}



@media only screen and (max-width: 300px) {
    .mt-texto-lineas-3{
        margin-top: 90px;
    }
}


@media only screen and (min-width: 300px) {
    .mt-texto-lineas-3{
        margin-top: 70px;
    }
}

@media only screen and (min-width: 380px) {
    .mt-texto-lineas-3{
        margin-top: 95px;
    }
}

@media only screen and (min-width: 450px) {
    .mt-texto-lineas-3{
        margin-top: 110px;
    }
}

@media only screen and (min-width: 500px) {
    .mt-texto-lineas-3{
        margin-top: 100px;
    }
}

@media only screen and (min-width: 600px) {
    .mt-texto-lineas-3{
        margin-top: 140px;
    }
}


@media only screen and (max-width: 450px) {
    .mt-texto-lineas-4{
        margin-top: 220px;
    }

}

@media only screen and (min-width: 450px) {
    .mt-texto-lineas-4{
        margin-top: 250px;
    }
}

.detalle-poligono {
  column-gap: 2.5rem;
  column-rule: 1px solid $gray-05;
  @include media-breakpoint-between(sm, md) {
    column-count: 2;
  }
  @include media-breakpoint-between(md, xl) {
    column-count: 3;
  }
  @include media-breakpoint-up(xl) {
    column-count: 5;
  }
}



//TIMELINE

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
  }
  
  
  div.item {
    display: flex;
    flex: auto;
    overflow-y: auto;
    padding: 0rem 1rem 0rem 1rem;
  }
  
  #timeline {
    position: relative;
    display: table; //*For Firefox
    height: 100%;
    margin: {
      left: auto;
      right: auto;
      top: 5rem;
    }
    div {
      &:after {
        content: '';
        width: 3px;
        position: absolute;
        top: .5rem;
        bottom: 0rem;
        left: 60px;
        z-index: 1;
        background: #01654D;
      }
    }
    h3 {
      position: -webkit-sticky;
      position: sticky;
      top: 5rem;
      color: #71415a;
      margin: 0 0 0 -20px;
      font: {
        size: 1em;
        weight: 800;
      }
      @media (min-width: 62em) {
        font-size: 1.1em;
      }
    }
    section.year {
    position: relative;
    &:first-child section{
      margin-top: -1.3em;
      padding-bottom: 0px;
    }
    section {
      position: relative;
      padding-bottom: 1.25em;
      margin-bottom: 2.2em;
      h4 {
        position: absolute;
        bottom: 0;
        font: {
          size: .9em;
          weight: 400;
        }
        line-height: 1.2em;
        margin: 0;
        padding: 0 0 0 89px;
        color: #01654D;
        @media (min-width: 62em) {
          font-size: 1em;
        }
      }
      ul {
        list-style-type: none;
        padding: 0 0 0 75px;
        margin: -1.35rem 0 1em;
        max-width: 32rem;
        font-size: 1em;
        @media (min-width: 62em) {
          font-size: 1.1em;
          padding: 0 0 0 81px;
        }
        &:last-child {
          margin: {
            bottom: 0;
          }
        }
        &:first-of-type:after {
          content: '';
          width: 20px;
          height: 20px;
          background: #01654D;
          border: 2px solid #01654D;
          @include border-radius(50%);
          position: absolute;
          left: 52px;
          top: 3px;
          z-index: 2;
        }
        li {
          margin-left: .5rem;
          &:before {
            content: '·';
            margin-left: -.5rem;
            padding-right: .3rem;
          }
          &:not(:first-child) {
            margin-top: .5rem;
          }
          span.price {
            color: #01654D;
            font-weight: 500;
          }
        }
      }
    }
  }
  }

  .numeralia-total{
    font-size: 34px;
    text-align: center;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: -0.5px;
  }

  .numeralia-title{
    font-size: 16px;
    font-weight: bold;
  }

  .numeralia-img{
    background-color: rgba(26, 26, 26, 0.24);
    padding: 5px;
    border-radius: 5px;
    margin-left: auto;
  }
  
  .disabled-btn{
    pointer-events: none;
    cursor: default;
  }
  
