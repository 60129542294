@include media("≥phone", "≤tablet"){
    $ms-base     : 1em       !default;
    $ms-ratio    : $minor-third    !default;
    h1 {font-size: ms(2); margin-top: ms(0);}
    h2 {font-size: ms(1);margin-top: ms(0);}
    h3 {font-size: ms(0);margin-top: ms(0);}
    h4 {font-size: ms(1);}
    p  {font-size: ms(-1);}

    $ms-base : .7em       !default;

    body{
        display: grid;
        justify-content: center;
    }

    #intro {
        flex-flow: column;
        width: 80%;
        margin: 15px;

    }
    button {
        padding: 10px 15px;
    }

    input .input-field-array {
        font-size: ms(-1);
        font-weight: 100;

    }
    @include media("<600px"){
        .hora {
            flex-flow:wrap;
        }
    }
     .reflow{
        flex-flow: wrap;
    }
}

#hero{
    @media screen and (max-width: '991px') {
        height: 220px;
        #descripcion{
            display: none;
        }
        #intro-blurb{
            top: 90px;
            right: auto;
       }
    }
    @media screen and (max-width: '768px') {
       #intro #logo-hero{
            width: 271px;
            height: auto
            img{
                background-size: 100% auto;
                height: auto;
            }
       }
       
    }
}

#descripcion-mb{
    position: relative;
    display: none;
     @media screen and (max-width: '991px') {
        display: block;
        p{
            font-size: 12px;
        }
        a.see-more{
            position: absolute;
            bottom: -21px;
            font-size: 12px;
            color: #7AD21E;
        }
    }
}

#personas-registradas .voluntarios{
    .col-4{
        padding-right: 2px;
        padding-left: 2px;
    }
    @media screen and (max-width: '1110px') {
        span{
            display: block;
            margin: 0 auto 5px auto;
        }
    }
    @media screen and (max-width: '767px') {
        span{
            display: inline-block;
        }
    }  
    @media screen and (max-width: '480px') {
        span{
            display: block;
        }
    }  
}

#searchbox {
    @media screen and (max-width: '1024px') {
        input.colonia-input{
            width: 340px;
        }
    }

    @media screen and (max-width: '700px') {
        label.colonia{
            display: none;
        }

        input.colonia-input{
            width: 57%;
            float: left;
            margin-left: 16px;
        }

        button{
            float: left;
        }
    }
}

#resultados-colonia{
    @media screen and (max-width: '700px') {
        width: 56%;
        left: initial;
        margin-left: 15px;
    }
    @media screen and (max-width: '480px') {
        width: 100%;
        left: initial;
        margin-left: 0px;
    }
}

#map{
    @media screen and (max-width: '991px') {
        height: 550px;
    }
    @media screen and (max-width: '768px') {
        height: 480px;
    }
}

.voluntarios-status-mobile{
    display: none;
    @media screen and (max-width: '991px') {
        display: block;
    }
}

#personas-registradas .voluntarios{
    .col-4{
        padding-right: 2px;
        padding-left: 2px;
    }
    @media screen and (max-width: '1110px') {
        span{
            display: block;
            margin: 0 auto 5px auto;
        }
    }
    @media screen and (max-width: '767px') {
        span{
            display: inline-block;
        }
    }  
    @media screen and (max-width: '480px') {
        span{
            display: block;
        }
    }  
}

.schedule__item label{
    @media screen and (max-width: '991px') {
        span{
            display: none;
        }
        
    }
}