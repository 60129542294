.coordinacion {
  color: $black;
  font-weight: bold;
  text-align: center;
  @include font-size($fs-32);
}

.coordinacion-responsable {
  break-inside: avoid-column;
  font-size: $fs-15;
  line-height: 1.2;

  dt {
    color: $color-primary;
    margin-bottom: 5px;
  }

  dd {
    color: $gray-09;
    margin-bottom: 0;

    &.tel {
      font-weight: $font-weight-bold;
    }
  }
}

.box {
  border: 2px solid #eeeeee;
  border-radius: 8px;
}

.table-box .table {
  color: $gray-07;
  font-size: $fs-13;
  margin-bottom: 0;
  @include media-breakpoint-up(sm) {

    td,
    th {

      &:not(:first-of-type) {
        text-align: center;
      }
    }
  }
  @include media-breakpoint-down(xs) {

    thead {
      display: none;
    }

    td {
      display: block;
      overflow: hidden;
    }

    tr {

      &:nth-of-type(odd) {
        background-color:  #f9f9f9;
      }

      + tr {
        border-top: 1px solid;
      }
    }

    tbody tr:first-of-type td:first-of-type {
      border-top: none;
    }
  }

  td,
  th {
    border-color: #979797;
    line-height: 1.2;
  }

  th {
    border-bottom-width: 1px;
    border-top: none;
  }

  td span {
    float: left;
    font-weight: bold;
    margin-right: 10px;
    width: 30%;
    @media (max-width: 376px) {
      display: block;
      font: none;
    }
  }
}
