#hero {
    background-image: url("../images/img_header-xl.jpg");
    background-attachment: fixed;
    background-size: cover;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 5px solid $color-primary;
    #cont_elementos {
        #logo {
            width: 35%;
            margin: 0 auto 0.5rem;
            img {
                width: 100%;
            }
        }
        #texto {
            width: 50%;
            margin: 0 auto;
            p {
                font-family: "Montserrat", sans-serif;
                font-size: 0.9rem;
                font-weight: 500;
                color: white;
                text-align: center;
            }
        }
    }
}

#navbar_container {
    border-bottom: 2px solid #f4f4f4;
    img {
        max-width: 550px;
    }
    #login_btn {
        font-family: "Open Sans", sans-serif;
        font-size: 0.9rem;
        font-weight: 500;
        color: white;
        background-color: $color_primary !important;
        &:hover {
            background-color: $verde-02 !important;
        }
        &.active {
            background-color: $verde-02 !important;
        }
    }
}

#breadcrumb {
    background-color: #f8f8f8;
    ul {
        overflow: auto;
        li {
            list-style: none;
            float: right;
            margin-left: 2.5rem;
            padding: 0.5rem 0;
            a {
                font-size: 0.85rem;
                font-family: "Source Sans Pro", sans-serif;
                font-weight: 600;
                color: $gray-17;
                text-decoration: none;
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
}

tspan {
    font-family: "Source Sans Pro", sans-serif !important;
}

/** Móvil **/
@media (max-width: 768px) {
    #hero {
        #cont_elementos {
            #logo {
                width: 50%;
                margin-bottom: 0.75rem;
            }
            #texto {
                width: 85%;
                p {
                    font-size: 0.75rem;
                }
            }
        }
    }
    #breadcrumb {
        ul {
            padding: 0;
            margin: 0;
            li {
                margin-left: 1rem;
                a {
                    font-size: 0.75rem;
                }
            }
        }
    }
}
