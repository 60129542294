.p-tipo_ayuda {
    .cont-titulo {
        text-align: center;
        margin-bottom: 2.5rem;
        h2 {
            font-family: "Metropolis";
            font-size: 1.75rem;
            font-weight: bold;
            color: $color-primary;
        }
    }
    .cont-seccion {
        .cs_interna {
            overflow: auto;
            h3 {
                font-size: 1.25rem;
                font-family: "Source Sans Pro", sans-serif;
                font-weight: bold;
                color: $gray-20;
                width: 100%;
                padding-bottom: 0.25rem;
                border-bottom: 1px solid $gray-20;
            }
            .cont-tipo_ayuda {
                margin: 1.75rem 0 3rem;
                .cont-elementos {
                    .cabecera {
                        overflow: auto;
                        display: flex;
                        align-items: flex-end;
                        min-height: 80px;
                        img {
                            float: left;
                        }
                        .titulo {
                            color: $color_primary;
                            font-size: 1.1rem;
                            font-family: "Source Sans Pro", sans-serif;
                            font-weight: 900;
                            letter-spacing: 0.05rem;
                            padding-bottom: 0.25rem;
                            margin-left: 1.5rem;
                            border-bottom: 2px solid $verde-02;
                        }
                    }
                    .descripcion {
                        display: block;
                        font-size: 1rem;
                        font-family: "Source Sans Pro", sans-serif;
                        font-weight: 600;
                        line-height: normal;
                        margin: 1rem 0;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                    .btn-tipo_ayuda {
                        display: inline-flex;
                        background-color: $gray-15;
                        padding: 0.25rem 0.75rem;
                        border-radius: 0.25rem;
                        text-decoration: none;
                        color: $gray-20;
                        font-size: 0.8rem;
                        font-family: "Source Sans Pro", sans-serif;
                        font-weight: 600;
                        img {
                            margin-right: 0.1rem;
                        }
                        &#btn-ayuda-fija {
                            margin-top: 1.25rem;
                        }
                        &:hover {
                            background-color: $gray-btn;
                        }
                        &.active {
                            background-color: $color-primary;
                            color: white;
                            pointer-events: none;
                            img {
                                filter: brightness(1000%);
                            }
                        }
                    }
                }
            }
            .cont-form_registro {
                margin: 0.75rem 0 3rem;
                label {
                    font-family: "Source Sans Pro", sans-serif;
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: $gray-20;
                    margin-bottom: 0;
                }
                input {
                    border: 1px solid $gray-btn;
                    padding: 0.5rem;
                    height: auto;
                }
            }
            .cont-cobertura_voluntarios {
                margin: 0 0 1.5rem;
                align-items: center;
                h3 {
                    width: auto;
                    border: none;
                    margin: 0;
                    line-height: normal;
                }
                .acotaciones {
                    overflow: auto;
                    margin: 0;
                    li {
                        float: left;
                        list-style: none;
                        font-family: "Source Sans Pro", sans-serif;
                        font-size: 0.8rem;
                        font-weight: 600;
                        margin-right: 1rem;
                        span {
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
            .cont-listado_voluntarios {
                margin: 1.75rem 0 3rem;
                .list-response {
                    #list-result {
                        overflow: auto;
                        margin: 1rem 0;
                        .person {
                            width: 30%;
                            float: left;
                            margin: 0 0 0.5rem;
                            padding-right: 1rem;
                            font-size: 0.85rem;
                            img {
                                max-width: 17.55px;
                                margin-top: -1px;
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }
                .turnos {
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 1.25rem;
                    div {
                        display: grid;
                        align-items: center;
                        h2 {
                            font-size: 1.5rem;
                            font-weight: bold;
                            line-height: normal;
                            text-align: right;
                            color: $gray-20;
                            margin-bottom: 0.75rem;
                            span {
                                color: $amarillo;
                            }
                        }
                        p {
                            text-align: right;
                            font-family: "Source Sans Pro", sans-serif;
                            font-weight: 600;
                            font-style: italic;
                            font-size: 0.9rem;
                            margin: 0;
                        }
                    }
                }
                .compartir {
                    text-align: center;
                    ul {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        li {
                            list-style: none;
                            font-family: "Source Sans Pro", sans-serif;
                            font-size: 0.9rem;
                            font-weight: 600;
                            margin-left: 0.75rem;
                            &:first-child {
                                margin: 0;
                            }
                            img {
                                max-width: 25px;
                            }
                        }
                    }
                }
            }
            .cont-especialidad {
                margin: 2.5rem 0;
                .btn_especialidad {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 68px;
                    background-color: $gray-04;
                    border-radius: 0.25rem;
                    cursor: pointer;
                    &:hover {
                        background-color: $verde-02;
                    }
                    &.active {
                        background-color: $color-primary;
                    }
                    label {
                        span {
                            font-family: "Source Sans Pro", sans-serif;
                            font-size: 0.9rem;
                            font-weight: 600;
                            color: white;
                            line-height: normal;
                        }
                    }
                }
            }
            .cont-ubicacion {
                margin: 1.75rem 0 3rem;
                text-align: center;
                img {
                    max-width: 30px;
                }
                p {
                    font-family: "Source Sans Pro", sans-serif;
                    font-weight: 700;
                    font-size: 1.1rem;
                    color: $color-primary;
                    line-height: normal;
                    margin: 0.75rem 0 2rem;
                    span {
                        display: block;
                        font-size: 1rem;
                        color: $verde-02;
                    }
                }
            }
            .cont-medio_transporte {
                margin: 0.75rem 0 3rem;
                select {
                    font-family: "Source Sans Pro", sans-serif;
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: $gray-20;
                    margin-bottom: 0;
                    border: 1px solid $gray-btn;
                    padding: 0.5rem;
                    height: auto;
                }
            }
            .cont-punto_partida {
                margin: 0.75rem 0 3rem;
                label {
                    font-family: "Source Sans Pro", sans-serif;
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: $gray-20;
                    margin-bottom: 0;
                }
                input {
                    border: 1px solid $gray-btn;
                    padding: 0.5rem;
                    height: auto;
                }
            }
            .cont-aviso_boton {
                text-align: center;
                font-family: "Source Sans Pro", sans-serif;
                font-weight: bold;
                letter-spacing: 0.5px;
                h4 {
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 0.25rem;
                }
                p {
                    font-size: 0.9rem;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0 0 1rem;
                    a {
                        color: $color-primary;
                        text-decoration: none;
                        font-weight: 600;
                        &:hover {
                            color: $verde-02;
                        }
                    }
                }
                .cont-checkbox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    label {
                        font-size: 0.8rem;
                        font-weight: 600;
                        line-height: normal;
                        margin: 0 0 0 0.75rem;
                    }
                }
            }
            /** Accordion **/
            .btn-accordion {
                font-size: 1.25rem;
                font-family: "Source Sans Pro", sans-serif;
                font-weight: bold;
                color: $gray-20;
                width: 100%;
                text-align: left;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                background: none;
                border: none;
                outline: none;
                span {
                    width: 0;
                    height: 0;
                    color: black;
                    border-top: 10px solid black;
                    border-right: 10px solid white;
                    border-left: 10px solid white;
                    border-bottom: 10px solid white;
                    font-size: 0px;
                    margin-bottom: -1rem;
                }
            }
            #accordion-como_funciona,
            #accordion-cosas_saber {
                margin: 0 0 3rem;
            }
            hr {
                border-top: 1px solid $gray-20;
                margin: 0.25rem 0 2.75rem;
            }
            .cont-como_funciona {
                justify-content: center;
                margin: 0;
                .cont-elementos {
                    text-align: center;
                    figure {
                        margin: 0;
                        figcaption {
                            font-family: "Open Sans", sans-serif;
                            font-size: 0.9rem;
                            font-weight: 700;
                            color: $color-primary;
                            letter-spacing: 0.5px;
                            margin: 0.25rem 0 0;
                        }
                    }
                    .cont-punto {
                        width: 30px;
                        height: 30px;
                        margin: 0 auto;
                        border: 1px solid $verde-02;
                        border-radius: 50%;
                        .pto {
                            width: 100%;
                            height: 100%;
                            background-color: $color-primary;
                            border-radius: inherit;
                            border: 4px solid #fff;
                        }
                    }
                    .cont-separador_v {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        div {
                            border-right: 1px solid $verde-02;
                            &.sdor_corto {
                                height: 15px;
                            }
                            &.sdor_largo {
                                height: 25px;
                            }
                        }
                    }
                    .cont-texto {
                        border: 1px solid $verde-02;
                        padding: 0.75rem;
                        p {
                            font-family: "Open Sans", sans-serif;
                            font-size: 0.75em;
                            font-weight: 600;
                            color: $gray-20;
                            line-height: normal;
                            text-align: center;
                            margin: 0;
                        }
                    }
                }
            }
            .cont-cosas_saber {
                justify-content: center;
                margin: 0;
                .l-interno {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0 1rem;
                    p {
                        font-family: "Montserrat", sans-serif;
                        font-weight: 500;
                        font-size: 0.8rem;
                        color: $verde-02;
                        margin: 0;
                        span {
                            font-size: 1rem;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .cont-voluntarios_registrados {
        background-color: #71415a;
        padding: 2.5rem 0;
        justify-content: center;
        div {
            text-align: center;
            font-family: "Source Sans Pro", sans-serif;
            color: #FFF;
            h2 {
                font-size: 1.5rem;
                font-weight: 700;
                margin: 0.5rem 0 0.75rem;
            }
            p {
                font-size: 1.25rem;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
        }
    }
}

#nombre-colonia {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    color: $gray-20;
}

.list-response {
    .label-cp-response {
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 700;
        font-size: 1rem;
    }
}

/* Formulario */

.cs_interna {
    #perfil {
    }
    #horario {
        .schedule__item {
            margin-bottom: 0.5rem !important;
            .schedule__title {
                font-family: "Source Sans Pro", sans-serif;
                font-weight: 700;
                font-size: 0.9rem;
                color: $gray-20;
                margin-bottom: 0;
                line-height: normal;
                span {
                    display: block;
                    font-weight: 800;
                    font-size: 0.75rem;
                    color: $color-primary;
                }
            }
            &.slot-dia {
                margin-bottom: 0.75rem !important;
                .text-truncate {
                    span {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}
