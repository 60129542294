#section-bottom {
    width: 100%;
    padding: 0.75rem 0;
    background-color: $black-01;
    .row {
        font-family: "Source Sans Pro", sans-serif;
        align-items: center;
        p {
            font-size: 0.7rem;
            color: white;
            letter-spacing: 0.25px;
            line-height: normal;
            margin: 0;
            &.primer_linea {
                font-weight: 800;
                span {
                    color: $gray-03;
                    font-weight: 500;
                }
            }
            &.segunda_linea {
                font-weight: 700;
            }
        }
        a {
            font-size: 0.7rem;
            color: white;
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: normal;
            margin: 0;
            &:hover {
                color: $color-primary;
            }
        }
    }
}
