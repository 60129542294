/* boton gris */
.flexGrow{
    justify-self: stretch;
    flex-grow: 2;
    height: 100%;
    flex-basis: 100%;
}

.formulario .texto-formulario{
  font-size: $fs-11;
  font-weight: bold;
  text-transform: uppercase;
  color: $gray-17;
}

.formulario .custom-checkbox > [type="checkbox"]{
  color: $color-aqua;
}


.filter-section-mapa{
  padding-top:0.7rem;
  a{
    font-weight: 700 !important;
  }
}
