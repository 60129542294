.mas_turnos {
    h2 {
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.09;
        letter-spacing: normal;
        text-align: right;
        color: #3f4141;
        span {
            font-size: 45px;
            font-weight: 900;
            color: #f7b500;
        }
    }
    .pasar_la_voz {
        p {
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            text-align: right;
            color: #3f4141;
        }
        h4 {
            font-size: 16px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.88;
            letter-spacing: normal;
            color: #3f4141;
        }
    }
}
