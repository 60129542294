@charset "UTF-8";

@import "variables";

@import "~leaflet/dist/leaflet.css";
@import "~leaflet-search/src/leaflet-search.css";
@import "~bootstrap/scss/bootstrap";

// Font Awesome 
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

@import "background";
@import "botones";
@import "botonesV2";
@import "buttons";
@import "collapse";
@import "fonts";
@import "forms";
@import "map";
@import "modal";
@import "scaffolding";
@import "search";
@import "slider";
@import "styles";
@import "switch";
@import "types";
@import "tabs_table";

// sentika_old
@import 'vars';
@import 'include-media';

// sentika_new
@import 'general_new';
@import 'mobile_new';

@import 'partials/tablero';


// Nueva version

@import 'inicio';
@import 'tipo-ayuda';
@import 'partials/header';
@import 'partials/footer';
@import 'form_fijo';
