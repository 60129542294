.collapse-link {

  svg {
    transition: $transition-base;
  }

  &[aria-expanded=false] svg {
    transform: rotate(-0.25turn);
  }
}

[data-toggle="collapse"] .fa:before {  
  content: "\f056";
}

[data-toggle="collapse"].collapsed .fa:before {
  content: "\f055";
}
