// Colores
$verde-02: #71415a;
$amarillo: #f7b500;

$color-primary: #71415a;
$color-blue: #00a4b4;
$color-orange: #ffaa43;
$color-green: #31b700;
$color-aqua: #00aea1;
$color-coral: #ff9881;
$color-yellow: #ffc300;
$color-yellow-opacity: rgba($color-yellow, 0.22);
$primary: $color-primary;
$title: #2b2b2b;
$greyish-brown: #424242;

// Escala de grises
$gray-nav: #f8f8f8;
$gray-sidebar: #fbfbfb;
$gray-border-nav: #ececec;
$gray-border-search: #979797;
$gray-modal: #f3f3f3;
$gray-nav2: #a5a5a5;
$gray-switch: #e9e9e9;
$gray-radio: #717171;
$gray-card: #dfdfdf;
$gray-btn: #d4d5d5;
$gray-01: #eaeaea;
$gray-02: #ebebeb;
$gray-03: #a5a5a5;
$gray-04: #8c8c8c;
$gray-05: #979797;
$gray-06: #6c6c6c;
$gray-07: #626262;
$gray-08: #6e6e6e;
$gray-09: #4a4e4e;
$gray-10: #4a4a4a;
$gray-11: #414141;
$gray-12: #3b3b3b;
$gray-13: #262629;
$gray-14: #9b9b9b;
$gray-15: #dedede;
$gray-16: #2c2c2c;
$gray-17: #5f5f5f;
$gray-20: #3f4141;
$black-01: #2e2e32;
$grey: $gray-03;

$text-colors: (
  "gray-dark": $gray-16,
  "gray-09": $gray-09,
  "gray-06": $gray-06,
  "black": black,
);

// Options
$enable-responsive-font-sizes: true;

// Spacing
$spacer: 1rem;
$spacers: (
  42: (
    $spacer * 2,
  ),
  425: (
    $spacer * 2.5,
  ),
);

//Typography
$font-family-base: "Metropolis", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$fs-10: 1rem;
$fs-11: 1.1rem;
$fs-12: 1.2rem;
$fs-13: 1.3rem;
$fs-14: 1.4rem;
$fs-15: 1.5rem;
$fs-16: 1.6rem;
$fs-17: 1.7rem;
$fs-18: 1.8rem;
$fs-19: 1.9rem;
$fs-20: 2rem;
$fs-22: 2.2rem;
$fs-23: 2.3rem;
$fs-24: 2.4rem;
$fs-25: 2.5rem;
$fs-28: 2.8rem;
$fs-32: 3.2rem;
$fs-325: 3.25rem;
$fs-51: 5.1rem;

//Forms
$custom-control-indicator-size: 1.8rem;
$custom-switch-width: 3.6rem;

//Svg's
$search-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23979797' d='M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z'/%3E%3C/svg%3E%0A");
$cancel-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23979797' d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.094l-4.157-4.104 4.1-4.141-1.849-1.849-4.105 4.159-4.156-4.102-1.833 1.834 4.161 4.12-4.104 4.157 1.834 1.832 4.118-4.159 4.143 4.102 1.848-1.849z'/%3E%3C/svg%3E%0A");
$select-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2331b700' d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/%3E%3C/svg%3E%0A");
$collapse-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ff7454' d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/%3E%3C/svg%3E%0A");
