//Buscador lupa

fieldset {
	display: inline-block;
	background: white;
	border: solid 0.1rem $gray-border-search;
	border-radius: 1.5rem;
	padding: 0 0 0 2rem;
}

.input01, .button01{
	position: relative;
	width: 20rem; /*ancho de caja*/
	height: 3rem;
	padding: 0;
}

.input01 {
	/*Caja del texto*/
	background: white;
	font-size: $fs-13;
	font-weight: bold;
	color: $gray-11;
	border: 0;
	z-index: 2;
	float: left;
}

.button01 {
	width: 30%;
	border: none;
	vertical-align: middle;
	background: transparent;
	float: right;
}

.input01:focus {
	outline: none;
	font-weight: bold;
}

.input01:focus + .button01 {
	outline: none;
}
/* modal widht */
.w-80 {
	width: 75%;
}
