$color-messages: #03604e;

.tab-selected{
  display: none;
  &.active{
    display: block;
  }
}

.btns-tab button {
  color: #8c8c8c;
  @include font-size(2.8rem);

  &.active {
    background-color: transparent;
    color: $color-messages;
  }
}

.section-recipients {
  position: sticky;
  top: 5px;

  h3 {
    color: $color-messages;
    font-weight: bold;
  }

  .subtitle{
    font-weight: bold;
    color: $color-messages;
    font-size: 15px;
  }
  textarea{
    height: 110px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }
  .type-recipients{
    width: 55%;
    float: left;
    padding-right: 9px;
  }
  .list-recipients{
    width: 45%;
    float:left;
    .recipients-names{
      display: none;
      height: 266px;
      position: relative;
      overflow: hidden;
      overflow-y: auto;
      @include media-breakpoint-down(sm) {
        height: auto;
      }
      &.active{
        display: block;
      }
    }
  }
  ul{
    list-style: none;
    padding: 0;
    li{
      min-height: 28px;
      padding-left: 5px;
      border-bottom: 1px solid #e9e9e9;
      &.active{
        background: #e9e9e9;
      }
      &:hover{
        background: #e9e9e9;
      }
      .form-check-label{
        font-size: 11px;
        padding-left: 7px;
        padding-top: 7px;
        width: 100%;
        padding-bottom: 4px;
      }
      .form-check-input{
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
  .btns-messages{
    position: relative;
    overflow: hidden;
    width: 100%;
    text-align: center;
    padding-top: 12px;
    .btn{
      color: #fff;
      font-size: 15px;
      font-weight: 700;
      margin: 0 6px;
      width: 95px;
    }
  }
}

.section-messages {

  h3 {
    color: #6dd400;
    font-weight: bold;

    &.answers {
      color: $color-messages;
    }
  }

  .section-messages-header {
    background: $white;
    border-bottom: 2px solid #979797;
  }

  .to{
    font-weight: bold;
    color: #1cbb55;
    &.answers{
      color: $color-messages;
    }
  }

  .from {
    font-size: 12px;
    font-weight: bold;
    color: #5b5b5b;
  }

  .message-content {
    font-weight: bold;
    color: #a5a5a5;
  }

  .content-messages-list {
    max-height: 100vh;
    min-height: 250px;
    overflow: auto;
    @include media-breakpoint-down(sm) {
      max-height: 45vh;
    }

    .message {
      border-bottom: 2px solid #e9e9e9;
      padding: 10px 0;

      span.count{
        position: absolute;
        bottom: 4px;
        right: 1px;
        background-color: #1bba55;
        border-radius: 7px 7px 7px 0;
        padding: 5px 5px;
        width: 29px;
        text-align: center;
        color: #fff;
        font-weight: 900;
        line-height: normal;
      }
    }
  }

  .content-answers-list{
    overflow: hidden;
    position: relative;
    height: 540px;
    overflow-y: auto;
    .answer{
      position: relative;
      overflow: hidden;
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 0px;
      p{
        color: #8c8c8c;
        padding-right: 21px;
        margin-bottom: 0;
        margin-top: 0;
        &.date-time{
          position: absolute;
          top: 10px;
          left: 64%;
          color: #000;
          font-size: 9px;
          font-weight: bold;
        }
        &.phone{
          color: $color-messages;
          font-weight: bold;
        }
        &.name{
          color: #000;
          font-weight: bold;
          margin-bottom: 5px;
        }
      }
    }
  }
}
