.p-inicio {
    .cont-titulo {
        text-align: center;
        h2 {
            font-family: "Metropolis";
            font-size: 2.25rem;
            font-weight: bold;
            color: $verde-02;
        }
    }

    .cont-seccion {
        margin-top: 2.5rem;
        .cont-subtitulo {
            overflow: auto;
            margin: 2rem auto;
            text-align: center;
            h3 {
                font-family: "Metropolis";
                font-size: 1.75rem;
                font-weight: bold;
                color: $color-primary;
                margin: 0;
            }
        }
        .cs_interna {
            overflow: auto;
            h3 {
                font-size: 1.25rem;
                font-family: "Source Sans Pro", sans-serif;
                font-weight: bold;
                color: $gray-20;
                width: 100%;
                padding-bottom: 0.25rem;
                border-bottom: 1px solid $gray-20;
            }
            .cont-como_funciona {
                justify-content: center;
                margin: 2.5rem 0;
                .cont-elementos {
                    text-align: center;
                    figure {
                        margin: 0;
                        figcaption {
                            font-family: "Open Sans", sans-serif;
                            font-size: 0.9rem;
                            font-weight: 700;
                            color: $color-primary;
                            letter-spacing: 0.5px;
                            margin: 0.25rem 0 0;
                        }
                    }
                    .cont-punto {
                        width: 30px;
                        height: 30px;
                        margin: 0 auto;
                        border: 1px solid $verde-02;
                        border-radius: 50%;
                        .pto {
                            width: 100%;
                            height: 100%;
                            background-color: $color-primary;
                            border-radius: inherit;
                            border: 4px solid #fff;
                        }
                    }
                    .cont-separador_v {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        div {
                            border-right: 1px solid $verde-02;
                            &.sdor_corto {
                                height: 15px;
                            }
                            &.sdor_largo {
                                height: 25px;
                            }
                        }
                    }
                    .cont-texto {
                        border: 1px solid $verde-02;
                        padding: 0.75rem;
                        p {
                            font-family: "Open Sans", sans-serif;
                            font-size: 0.75em;
                            font-weight: 600;
                            color: $gray-20;
                            line-height: normal;
                            text-align: center;
                            margin: 0;
                        }
                    }
                }
            }
            .cont-cosas_saber {
                justify-content: center;
                margin: 2.5rem 0;
                .l-interno {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 0 1rem;
                    p {
                        font-family: "Montserrat", sans-serif;
                        font-weight: 500;
                        font-size: 0.8rem;
                        color: $verde-02;
                        margin: 0;
                        span {
                            font-size: 1rem;
                            font-weight: 600;
                        }
                    }
                }
            }
            .cont-tipo_ayuda {
                margin: 1.5rem 0 2.5rem;
                .cont-elementos {
                    .cabecera {
                        overflow: auto;
                        display: flex;
                        align-items: flex-end;
                        min-height: 80px;
                        img {
                            float: left;
                        }
                        .titulo {
                            color: $color_primary;
                            font-size: 1.1rem;
                            font-family: "Source Sans Pro", sans-serif;
                            font-weight: 900;
                            letter-spacing: 0.05rem;
                            padding-bottom: 0.25rem;
                            margin-left: 1.5rem;
                            border-bottom: 2px solid $verde-02;
                        }
                    }
                    .descripcion {
                        display: block;
                        font-size: 1rem;
                        font-family: "Source Sans Pro", sans-serif;
                        font-weight: 600;
                        line-height: normal;
                        margin: 1rem 0;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                    .btn-tipo_ayuda {
                        display: inline-flex;
                        background-color: $gray-15;
                        padding: 0.25rem 0.75rem;
                        border-radius: 0.25rem;
                        text-decoration: none;
                        color: $gray-20;
                        font-size: 0.8rem;
                        font-family: "Source Sans Pro", sans-serif;
                        font-weight: 600;
                        img {
                            margin-right: 0.1rem;
                        }
                        &#btn-ayuda-fija {
                            margin-top: 1.25rem;
                        }
                        &:hover {
                            background-color: $gray-btn;
                        }
                    }
                }
            }
            .cont-cobertura_voluntarios {
                margin: 0;
                align-items: center;
                h3 {
                    width: auto;
                    border: none;
                    margin: 0;
                    line-height: normal;
                }
                .acotaciones {
                    overflow: auto;
                    margin: 0;
                    li {
                        float: left;
                        list-style: none;
                        font-family: "Source Sans Pro", sans-serif;
                        font-size: 0.8rem;
                        font-weight: 600;
                        margin-right: 1rem;
                        span {
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }
    }

    .cont-voluntarios_registrados {
        background-color: #71415a;
        padding: 2.5rem 0;
        justify-content: center;
        div {
            text-align: center;
            font-family: "Source Sans Pro", sans-serif;
            color: #FFF;
            h2 {
                font-size: 1.5rem;
                font-weight: 700;
                margin: 0.5rem 0 0.75rem;
            }
            p {
                font-size: 1.25rem;
                font-weight: 600;
                line-height: normal;
                margin: 0;
            }
        }
    }
}