.fotografia img{
  width: 100%;
  transition: all .5s ease;
}

.fotografia img:hover{
  transform:scale(1.1);
}

.modal-header-customize{
  border-bottom: 0;
}

@media (min-width: 57.6rem){
  .modal-dialog-customize{
    max-width: 60%;
    margin: 5rem auto;
  }
}

.modal.fade.show.back{
  background-color: rgba(black, .5);
}


.modal-header-customize button{
  outline: none;
}

.modal-header-customize button.active{
  background-color: transparent;
}
