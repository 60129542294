[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

#map_emergencia {
  height: 100vh;
  max-height: 800px;
  width: 100%;
}

.map-header {
  pointer-events: auto;

  .leaflet-control-search {
    border: 1px solid $gray-border-search;
    border-radius: 15px;
    display: flex;
    margin: 0;
    max-width: 100%;
    padding: 0 ($spacer * 0.75);
    pointer-events: auto;
    width: 380px;

    .search-input {
      border: none;
      color: $gray-11;
      font-size: $fs-12;
      font-weight: $font-weight-bold;
      padding-right: 30px;
      width: 100%;

      &::placeholder {
        color: #414141;
        opacity: 1;
      }
    }

    .search-button {
      background: $search-icon center / 18px no-repeat;
      background-position: center;
      background-size: 18px;
      border-radius: inherit;
      bottom: 0;
      margin: auto;
      position: absolute;
      right: 10px;
      top: 0;

      &:hover {
        background: $search-icon center / 18px no-repeat $gray-modal;
      }
    }

    .search-alert {
      position: absolute;
      top: 30px;
    }

    .search-cancel {
      background: $cancel-icon center / contain no-repeat;
      height: 20px;
      right: 40px;
      width: 20px;
    }

    .search-tooltip {
      width: 100%;
    }
  }
}

.leaflet-container {

  .leaflet-control-container .leaflet-control-zoom {
    border: none;
    margin-bottom: ($spacer * 2);

    a {
      box-shadow: 0 2px 8px rgba($black, 0.5);
      font: $font-weight-bold 3.6rem $font-family-base;
      height: 3.6rem;
      line-height: 3.6rem;
      width: 3.6rem;

      &:first-child {
        margin-bottom: 2px;
      }
    }
  }
}

.map-sidebar {
  height: calc(100% - 75px);
  margin-top: 70px;
  max-width: 55vw;
  width: 240px;

  .custom-control-label {
    color: $gray-08;

    &::before {
      background-color: rgba($gray-sidebar, 0);
      border: 2px solid $gray-radio;
    }
  }

  &__content {
    background-color: rgba($gray-sidebar, 0.97);
    border-radius: 5px;
    box-shadow: 0 2px 15px rgba($black, 0.5);
    max-height: 100%;
    overflow: auto;
    padding: $spacer ($spacer * 0.75) 0;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &.show {
      pointer-events: auto;
    }
  }

  &__separator {
    border-top: 1px solid $gray-border-search;
  }

  &__radio {
    padding-left: 3.3rem;

    .custom-control-label {
      font-size: $fs-13;
      font-weight: 600;

      &::before,
      &::after {
        height: 2.3rem;
        left: -3.3rem;
        width: 2.3rem;
      }

      &::after {
        background-size: 70%;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {

      &::before {
        background-color: rgba($gray-sidebar, 0);
        border-color: $gray-radio;
      }

      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300a1b1'/%3e%3c/svg%3e");
      }
    }
  }

  &__select {
    background-image: $select-icon;
    background-size: 14px;
    border-radius: 7px;
    border-color: $color-green;
    color: $gray-10;
    font-weight: bold;
    height: 3.8rem;
    padding-right: 2.75rem;
  }

  &__card {
    border: 2px solid $gray-card;
    border-radius: 4px;

    + .card {
      margin-top: 3px;
    }

    .card-header {
      background: none;
      border: none;
    }

    [data-toggle]::after {
      background: $collapse-icon center / contain no-repeat;
      content: '';
      height: 7px;
      width: 7px;
    }
  }

  &__checkbox {

    + .custom-checkbox {
      margin-top: 1rem;
    }

    .custom-control-label {
      font-size: $fs-11;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $gray-12;
      border-color: $gray-12;
    }
  }
}

.map-toggle {
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: center;
  padding: 0;
  pointer-events: auto;
  position: absolute;
  top: -5px;
  transition: $btn-transition, all 0.1s ease-in-out;
  width: 42px;
  z-index: 1;

  &__bar {
    position: relative;

    &,
    &::before,
    &::after {
      background-color: $white;
      height: 6px;
      transition: $transition-base;
      width: 22px;
    }

    &::before,
    &::after {
      content: '';
      left: 0;
      position: absolute;
    }

    &::before {
      top: 8px;
    }

    &::after {
      bottom: 8px;
    }
  }

  &--left {
    left: 5px;

    &[aria-expanded=true] {
      left: calc(100% - 20px);

      .map-toggle__bar {

        &::before {
          transform: translateY(-57.75%) rotate(0.125turn);
        }

        &::after {
          transform: translateY(57.75%) rotate(-0.125turn);
        }
      }
    }
  }

  &--right {
    right: 5px;

    &[aria-expanded=true] {
      right: calc(100% - 20px);

      .map-toggle__bar {
        left: 1px;

        &::before {
          transform: translateY(-57.75%) rotate(-0.125turn);
        }

        &::after {
          transform: translateY(57.75%) rotate(0.125turn);
        }
      }
    }
  }

  &[aria-expanded=true] .map-toggle__bar {
    background-color: rgba($warning, 0);

    &::before,
    &::after {
      height: 8px;
      width: 20px;
    }
  }

  &:not([aria-expanded=true]) {
    box-shadow: 0 2px 8px rgba($black, 0.5);
  }
}

.fa-icon {margin: 0;}

.status-comite{
  list-style: none;
  position: absolute;
  list-style: none;
  z-index: 999999;
  bottom: 0;
  right: 0;
  padding: 5px 25px;
  background-color: rgba(255, 255, 255, 0.51);

  li{
    display: inline-block;
    margin-left: 15px;
    color: #6d7278;
    font-weight: 600;
    font-size: 15px;

    span {
      display: inline-block;
      width: 15px;
      height: 15px;
      background: #007aae;
      vertical-align: middle;
      margin-right: 5px;

      &.no-instalado{
        background: #b6b6b6;
      }
    }
  }
}
