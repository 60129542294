.color-primary {
    background-color: $color-primary;
}
/* inicia tablero02 */
.btn1 {
    width: 240px;
    max-width: 80%;
    height: 47px;
    margin: 2px;
    position: relative;
}

.bg-color-coral {
    background-color: $color-coral;
    opacity: 0.92;
    border-radius: 5px;
}

.bg-color-gray12 {
    background-color: $gray-12;
    border-radius: 5px;
    /* height: 99px; */
}
